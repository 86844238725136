import { onSnapshot, collection } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../Firebase/Firebase';

export class TopListItem {
    constructor(id, data) {
        this.id = id;
        this.data = data;
    }
}

export const useToplistController = () => {
    const [items, setItems] = useState([]);

    useEffect(() => {   
        const colRef = collection(db, 'toplist');
        
        const unsubscribe = onSnapshot(colRef, (querySnapshot) => {
            const topListItems = querySnapshot.docs.map((doc) => new TopListItem(doc.id, doc.data()));
            console.debug("🔥 Received toplist items: ", topListItems);
            setItems(topListItems);
        });
        
        return () => {
            unsubscribe() 
        };
    }, []);


    return items;
}

