import './index.css'
import { makeStyles } from '@mui/styles'
import { ReactComponent as Logo } from './figgili.svg'
import { useToplistController } from './Controllers/ToplistController'

const useStyles = makeStyles( theme => ({
  "@global": {
    html: {
      margin: 0,
      padding: 0,
      fontSize: "clamp(13px, 1vw, 30px)",
    },
    body: {
      backgroundColor: "#1d2837",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    main: {
      width: "25vw",
      color: "white",
    },
  },
  textBox: {
    position: "absolute",
    color: "#b17f5c",
    fontFamily: "'Lobster', cursive",
    fontSize: "2rem",
    padding: "2rem",
    bottom: 0,
    left: 0,
    width: "100%",
    textAlign: "center",
  },
}))

const App = () => {

  const classes = useStyles()
  //const items = useToplistController()

  //console.debug("Items: ", items)

  return (
    <>
      <main>
        <Logo />

        {/*Items: {items.length}
        {
        items.map((item) => (
          <div key={item.id}>#{item.id}</div>
        ))
        }*/}
          
      </main>
      <div className={classes.textBox}>The coder is hard at work, and the monkey is on its way!</div>
    </>
  );
}

export default App;
