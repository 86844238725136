// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { connectFirestoreEmulator } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_PcJieIOb2tdi6RKT3NXVjrVAlwKexmQ",
  authDomain: "figgili-the-punk-monkey.firebaseapp.com",
  projectId: "figgili-the-punk-monkey",
  storageBucket: "figgili-the-punk-monkey.appspot.com",
  messagingSenderId: "226251585421",
  appId: "1:226251585421:web:483e2ab8b86f91c8792fc4",
  measurementId: "G-62DNXQBLYR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
connectFirestoreEmulator(db, 'localhost', 8080);

console.debug("🔥 Firebase has been initialized");
console.debug("🔥 Firebase app: ", app);
console.debug("🔥 Firebase storage: ", getStorage(app));
console.debug("🔥 Firebase analytics: ", getAnalytics(app));

export {  db };
